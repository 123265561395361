import { Instagram, Whatsapp, Facebook, Youtube, Linkedin, Mail } from "../../../assets";

const socialIcons = [
    {
        title: 'Instagram',
        icon: Instagram,
        link: 'https://www.instagram.com/evolv_fit/',
    },
    {
        title: 'Whatsapp',
        icon: Whatsapp,
        link:
            'https://wa.me/919334444441?text=I%27m%20interested%20in%20joining%20Evolv',
    },
    {
        title: 'Facebook',
        icon: Facebook,
        link: 'https://www.facebook.com/groups/evolvwithus',
    },
    {
        title: 'Youtube',
        icon: Youtube,
        link: 'https://www.youtube.com/channel/UCz9AVVLWTbMMrnM266yo6Qw',
    },
    {
        title: 'Linkedin',
        icon: Linkedin,
        link: 'https://www.linkedin.com/company/evolvfit',
    },
    {
        title: 'Mail',
        icon: Mail,
        link: 'mailto:evolv.contact@gmail.com',
    },
]

export { socialIcons }