import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useLocation} from 'react-router-dom';
import TrainerProfile from '../TrainerProfile';
import Loader from '../../components/GenericPages/Loader';
import PageNotFoundPage from '../../components/GenericPages/PageNotFound';
import BASE_URL from '../../service/baseUrl';

function PageNotFound() {
    const {pathname} = useLocation();
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const [showTrainer, setShowTrainer] = useState(false);
    const fetchData = async () => {
        // const url = "https://api1.evolvfit.in/s2/trainerProfile";
        try {
            setLoading(true)
            const res = await axios.get(`${BASE_URL}/trainerProfile?profileName=${pathname.slice(1)}`);
            // console.log("res api ", res)
            if(res?.data?.code==1){
                setShowTrainer(true)
                setData(res?.data)
            }
        } catch (error) {
            // console.log(" api error",error)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData();
    },[pathname])
    
    return ( loading ? <Loader/> : 
        showTrainer ? <TrainerProfile data={data}/> : 
        <PageNotFoundPage/>
        // <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        //     <h1 style={{fontSize: '5rem'}}>Page Not Found</h1>
        // </div>
     );
}

export default PageNotFound;