import React, { useEffect } from 'react';
import { useState } from 'react';
import './index.scss'
import YtIcon from '../../../assets/youtube-icon.png'
import VideoWallpaper from '../../../assets/images/videoWallPaper.avif';
import VideoPlayer from '../../../components/Common/VideoPlayer';

const Video = ({video, triggerModal, idx, id, setId}) => {
    const [clicked,setClick] = useState(false)
    const [isMobile, setMobile] = useState(getWindowSize()<920);

    function getWindowSize() {
        const {innerWidth} = window;
        return innerWidth;
    }

    // useEffect(() => {
    //     function handleWindowResize() {
    //         setMobile(getWindowSize()<920);
    //     }

    //     window.addEventListener('resize', handleWindowResize);

    //     return () => {
    //     window.removeEventListener('resize', handleWindowResize);
    //     };
    // }, []);

    return (
        <>
            {id===idx
            ?
            // <div className='slide'>                
            //     <div className='iframe-wrapper'>
                    <VideoPlayer url={video.videoUrl}/>
            //     </div>
            //  </div>
            :<div className='slide'>
                <img className='slide-thumb' src={video?.thumbnailUrl ? video.thumbnailUrl : VideoWallpaper} alt='youtube' />
                <img
                    src={YtIcon}
                    className='yt-icon' 
                    onClick={()=>{
                        triggerModal(idx)
                    }}
                    alt='icon'
                />
                <small className='videoDescription'>{video?.description}</small>
            </div>
            }
        </>
    )
}

export default Video