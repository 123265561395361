import React, { useEffect, useRef, useState } from 'react';
import { AppStore, GooglePlay, QuoteClose, QuoteOpen } from "../../assets";
import "./style.scss";
import "./transformation.scss";
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const AboutTrainer = ({profileRef, backgroundColor, textColor}) => {
    const [isHidden, setHidden] = useState(false)
    const [doAnimate, setAnimate] = useState(true)
    const containerRef = useRef()
    
    // useScrollPosition(
    //     ({ currPos }) => {
    //       if(currPos.y<320){
    //         setHidden(false)
    //         setAnimate(true)
    //       }
    //       else if(currPos.y>600){
    //         setHidden(true)
    //         setAnimate(false)
    //       }
    //     }, [], containerRef
    // )
    useEffect(()=>{
        setTimeout(()=>{
            setAnimate(true)
            setHidden(false)
        },300)
    },[])
    return (
        <div  ref={containerRef} className={`container`}>
            {profileRef?.displayPictureUrl && (
              <div style={{ padding: "20px" }} className={`${doAnimate?'jumpIn':''} ${isHidden?'hide':''}`}>
                <img
                  src={profileRef?.displayPictureUrl}
                  alt="logo"
                  style={{ width: "30rem", borderRadius: "10px" }}
                />
              </div>
            )}
            <div>
              {profileRef?.description && (
                <div className={`testimonial ${doAnimate?'jumpIn':''} ${isHidden?'hide':''}`}>
                  <img src={QuoteOpen} className="left-quote" alt={'left quote'}/>
                  <p>{profileRef?.description}</p>
                  <img src={QuoteClose} className="right-quote" alt={'right quote'} />
                </div>
              )}
              <div className="connect-app">
          
          <a href="https://play.google.com/store/apps/details?id=com.evolvfitprivatelimited.user&hl=en&gl=US" target="_blank">
            <img src={GooglePlay} alt="google-play" height="48px"></img>
          </a>
          <a href="https://testflight.apple.com/join/sru5N6Fc" target="_blank">
            <img src={AppStore} alt="google-play" height="48px"></img>
          </a>
        </div>

              <div className="infoContainer">
                <div
                  style={{ backgroundColor: backgroundColor, color: textColor }}
                  className={`infoBlock ${doAnimate?'jumpIn':''} ${isHidden?'hide':''}`}
                >
                  <div>People Trained</div>
                  <p>{profileRef?.peopleTrained ?? "-"}</p>
                </div>
                <div
                  style={{ backgroundColor: backgroundColor, color: textColor }}
                  className={`infoBlock ${doAnimate?'jumpIn':''} ${isHidden?'hide':''}`}
                >
                  <div>Experience</div>
                  <p>
                    {profileRef?.experience ?? "-"} Years
                  </p>
                </div>
              </div>
            </div>
          </div>
    )
}
export default AboutTrainer
