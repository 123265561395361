import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'

const VideoPlayer = ({ url }) => {
    const [cachedUrl, setCachedUrl] = useState(null)

    useEffect(() => {
        console.log('Debug 1------', url)

        let cache = null // Declare cache outside of the async function scope

        const fetchAndCacheVideo = async () => {
            try {
                const cacheName = 'my-video-cache'
                cache = await caches.open(cacheName)
                const cachedResponse = await cache.match(url)

                if (cachedResponse) {
                    // If the video is already cached, use the cached response
                    const blob = await cachedResponse.blob()
                    const cachedFile = new File([blob], 'video.mp4', {
                        type: 'video/mp4',
                    })
                    setCachedUrl(URL.createObjectURL(cachedFile))
                    // console.log('Debug 2---------', cachedFile)
                } else {
                    // If the video is not cached, fetch it and store it in the cache
                    const response = await fetch(url)
                    // console.log('Debug 3---------', response)
                    await cache.put(url, response.clone())
                    const blob = await response.blob()
                    // console.log('Debug 4---------', blob)
                    const downloadedFile = new File([blob], 'video.mp4', {
                        type: 'video/mp4',
                    })
                    // console.log('Debug 5---------', downloadedFile)
                    setCachedUrl(URL.createObjectURL(downloadedFile))
                }
            } catch (error) {
                console.error(error)
            }
        }

        fetchAndCacheVideo()

        return () => {
            // Remove the cache and the video when the component is unmounted
            if (cache) {
                cache.delete(url)
            }
            URL.revokeObjectURL(cachedUrl)
            console.log("cahed uerl ", cachedUrl)
        }
    }, [url])

    return (
        <ReactPlayer
            url={cachedUrl}
            controls={true}
            playsinline
            width={'fit-content'}
            height={'auto'}
        />
    )
}

export default VideoPlayer


