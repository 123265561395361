import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import './index.scss';
import './assets/App.scss'
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App/>)