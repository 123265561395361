import React,{Suspense} from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import Loader from './components/GenericPages/Loader'
import routes from './routes'
import Footer from "./components/Footer"
import './stylesheet/common.scss'
import Navbar from './components/Navbar'
import ScrollToTop from './components/Common/ScrollToTop'
import MainWebLayout from './components/Layout'
import TrainerProfile from './Pages/TrainerProfile'
import PageNotFound from './Pages/PageNotFound'

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <ScrollToTop/>
        {/* <Navbar/>
        <Routes>
          {routes.map((route,index)=>{
            return (<Route
            key={index}
            path={route.path}
            exact={route.exact}
            name={route.name}
            element={<route.component/>}
            />)
          })}
        </Routes>
        <Footer/> */}
        <Routes>
          {routes.map((route,index)=>{
            return (<Route
            key={index}
            path={route.path}
            exact={route.exact}
            name={route.name}
            element={
              <MainWebLayout>
                <route.component/>
            </MainWebLayout>
          }
            />)
          })}
          <Route path="*" exact='false' element={<PageNotFound />} routes={routes}/>
        </Routes>
        
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
