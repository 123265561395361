import React, { memo, useEffect, useRef, useState } from "react";
import axios from "../../../service/axios-secure";
import { startOfDay, endOfDay } from "date-fns";
import DatePicker from "react-datepicker";
import { CloseButtonIcon } from "../../../assets";
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber, parsePhoneNumber} from 'react-phone-number-input'
import "./style.scss";
import { Modal } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const Plans = ({ data, backgroundColor, textColor, pathname, setShowAuthModal, user }) => {
  const programs = data?.trainer?.profileRef?.programOfferedRefs;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showDateModal, setShowDateModal] = useState(false);
  const [receiverID, setReceiverID] = useState("");
  const [programId, setProgramId] = useState()
  const [program, setProgram] = useState();
  const [phone, setPhone] = useState()
  const [msg, setMsg] = useState({msg: '',code: 1});
  const [loading, setLoading] = useState(false)
  const [promocode, setPromocode] = useState({name: '', id:null})
  const [promocodeData, setPromocodeData] = useState([])
  const [isHidden, setHidden] = useState(false)
  const [doAnimate, setAnimate] = useState(true)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const containerRef = useRef()
    
    // useScrollPosition(
    //     ({ currPos }) => {
    //       // if(currPos.y<320){
    //       //   setHidden(false)
    //       //   setAnimate(true)
    //       // }
    //       // else if(currPos.y>600){
    //       //   setHidden(true)
    //       //   setAnimate(false)
    //       // }
    //       console.log("plan----------", currPos.y, window.outerHeight);
    //       if(currPos.y> window.outerHeight){
    //         setHidden(true)
    //         setAnimate(false)
    //       }else{
    //         setHidden(false)
    //         setAnimate(true)
    //       }
    //     }, [], containerRef
    //     )

  const fetchPromocodes = async ()=>{
    try {
      const res = await axios.get(`users/promocodes?trainerId=${data?.trainer?._id}`)
      setPromocodeData(res?.data?.promocodes)
      // console.log("-----promocodes-------", res?.data)
    } catch (error) {
        // console.log("error while fetching promocodes ", error)
    }
  }

  const fetchUPIDetails = async () => {
    try {
      const response = await axios.get(
        `payment/QRCode?trainerId=${data?.trainer?._id}`
      );
      setReceiverID(response?.data?.receiverDetails?.recieverId);
    //   console.log("upi details ", response?.data?.receiverDetails?.recieverId);
    } catch (e) {
    //   console.log("error while fetching upi details ", e);
    }
  };
  useEffect(()=>{
    setMsg({msg: '',code: 1})
  },[showDateModal])
  useEffect(() => {
    if(user){
        fetchUPIDetails();
        fetchPromocodes();
    }
  }, [pathname, user]);

  const onDateChange = (date) => {
    let endDate = new Date(date);
    setStartDate(startOfDay(new Date(date)));
    endDate.setDate(endDate.getDate() + program?.duration - 1);
    setEndDate(endOfDay(endDate));
  };
  const selectProgram=(programType, program )=>{
        if(!user){
            setShowAuthModal(true)
        }else{
            setShowDateModal(true);
            setProgram(programType);
            setProgramId(program?._id)
            // console.log("program ", programType, program);
        }
  }
  const validate=()=>{
    let isValid = true
    if(!(phone && isValidPhoneNumber(phone))){
        isValid = false
        setMsg({msg: 'Please enter valid phone number',code: 0})
    }
    if(!startDate || !endDate){
        isValid = false
        setMsg({msg: 'Please select start and end date',code: 0})
    }
    return isValid
  }
  useEffect(() => {
      if(msg?.msg && msg?.code===0){
        setTimeout(()=>{
            setMsg()
        },4500)
      }
  },[msg])
  const enrollProgram = async () => {
    let number = parsePhoneNumber(phone)
    let countryCode = '+' + number.countryCallingCode
    let phoneNumber = number.nationalNumber
    if(validate()){
        setLoading(true)
        try {
            let response = await axios.post(
            '/payment/bypass/initiate',
            {
                programOfferedId: programId,
                programType: program?._id,
                paymentType: 'programRegistration',
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                createdAt: new Date(),
                receiverId: receiverID,
                promocode: promocode?.id!==null ? {_id: promocode?.id} : null,
                details:{countryCode: countryCode,phoneNumber:phoneNumber}
                //   promocode: promocode,
            },
        );
        setLoading(false)
        // setShowDateModal(false);
        if(response?.data?.code==1){
            setMsg({msg: 'Thank you for your interest in our program. We will get back to you shortly.',code: 1})
            setShowDateModal(false)
            setShowSuccessModal(true)
            
        }else{
            setMsg({msg: 'Some error occurred. Please try again!',code: 0})
        }
            // console.log(" purchase program ", response)
        } catch (error) {
            // console.log("purchase program api error ", error);
            setMsg({msg: 'Some error occurred. Please try again!',code: 0})
        }
    }
  };
  const applyPromocode=()=>{
    if(promocode?.name){
    let promo=promocodeData?.filter((promo)=>promo.name===promocode?.name && promo.programType===program?._id)
    // let promo = filterPromoName?.filter((promo)=>promo.programType===program?._id)

    console.log("promo ", promo)
    setPromocode(prev=>({...prev, id: promo[0]?._id}))
    if(promo?.length>0){
        setMsg({msg: 'Congratulations! You save ₹'+promo[0]?.discount+'',code: 1})
    }else{
        setMsg({msg: 'Invalid promocode',code: 0})
    }}
  }
  return (
    <div className="plan-container" ref={containerRef}>
      <div className={`${doAnimate?'jumpIn':''} ${isHidden?'hide':''}`}>
          <h1>Programs</h1>
      </div>
      <div className="main-PlanCard">
      <Modal show={showSuccessModal} centered contentClassName="modal-content">
        <div className="modal-form">
            <div className="close-button" onClick={() => setShowSuccessModal(false)}>
                <img
                    className="close-button-icon"
                    src={CloseButtonIcon}
                    alt="close-icon"
                />
            </div>
          <table>
            <tbody>
              <tr>
                <td className="column">Thank you for your interest in our program. We will get back to you shortly.</td>
              </tr>
              
            </tbody>
          </table>
          <div className="button-container">
                <div className="proceed-button" 
                onClick={() =>setShowSuccessModal(false)}
                style={{background: backgroundColor, color: textColor}}>Ok</div>
          </div>
        </div>
      </Modal>
      <Modal show={showDateModal} centered contentClassName="modal-content">
        <div className="modal-form">
            <div className="close-button" onClick={() => setShowDateModal(false)}>
                <img
                    className="close-button-icon"
                    src={CloseButtonIcon}
                    alt="close-icon"
                />
            </div>
          <table>
            <tbody>
              <tr>
                <td className="column">Select Program Start Date<span className="asterisk">*</span></td>
                <td className="column">
                  <DatePicker
                    placeholderText="Select date"
                    minDate={new Date()}
                    selected={startDate}
                    onChange={(date) => onDateChange(date)}
                  />
                </td>
              </tr>
              <tr>
                <td className="column">Enter Mobile No.<span className="asterisk">*</span></td>
                <td className="column">
                <PhoneInput
                    placeholder="Enter phone number"
                    defaultCountry="IN"
                    value={phone}           
                    onChange={setPhone}/>
                </td>
              </tr>
              <tr>
                <td className="column">Promocode</td>
                <td className="column">
                  <input
                    type="text"
                    placeholder="Enter promocode"
                    value={promocode?.name}
                    onChange={(e) => setPromocode(prev=>({...prev, name: e.target.value}))}
                  />
                </td>
                <td className="column"><div className="applyButton" onClick={applyPromocode}>Apply</div></td>
              </tr>
              <tr style={{height: '30px'}}>
              { msg?.msg && 
                <td colSpan={2} className="displayMsg" style={{color: msg.code === 0 ? 'red' : 'green'}}>{msg?.msg ?? ''}</td>
              }
              </tr> 
            </tbody>
          </table>
          
          <div className="button-container">
                <div className="proceed-button" 
                onClick={() => enrollProgram()}
                style={{background: backgroundColor, color: textColor}}>Proceed</div>
          </div>
        </div>
      </Modal>
      
      <div className={`plan-card ${doAnimate?'jumpIn':''} ${isHidden?'hide':''}` } >
      
        {programs?.map((program, idx) => (
          <div key={idx} style={{ margin: "20px", flex: 1 }}>
            <h3>{program?.name}</h3>
            <div
              style={{
                backgroundColor: backgroundColor,
                color: textColor,
                padding: "10px 20px",
                borderRadius: "8px",
              }}
            >
              <div>
                {program?.highlights?.map((highlight, idx) => (
                  <div key={idx} className="highlight">
                    {highlight}
                  </div>
                ))}
              </div>
              <div className="description">{program?.description}</div>
              {program?.programTypes?.map((programType, idx) => (
                <div key={idx} className="program-type">
                  <span>{programType?.duration} days</span>
                  {programType?.discount && programType?.discount > 0 ? (
                    <span>
                      <s className="fontWeight">₹ {programType?.price}</s>{" "}
                      &nbsp;₹ {programType?.price - programType?.discount}
                    </span>
                  ) : (
                    <span>₹ {programType?.price}</span>
                  )}
                  <button
                    onClick={() => selectProgram(programType, program)}
                    style={{
                      border: "none",
                      padding: "8px 16px",
                      borderRadius: "4px",
                      fontWeight: "500",
                      fontSize: "14px",
                      margin: "5px",
                      color: 'black',
                      backgroundColor: 'white'
                    }}
                  >
                    Select
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default memo(Plans);
