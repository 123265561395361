import React, { useRef, useState } from 'react';
import "./style.scss";
import "./transformation.scss";
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const TainerExperience = ({profileRef, backgroundColor, textColor}) => {
    const [isHidden, setHidden] = useState(false)
    const [doAnimate, setAnimate] = useState(true)
    const containerRef = useRef()
    
    // useScrollPosition(
    //     ({ currPos }) => {
    //       if(currPos.y>window.outerHeight){
    //           setHidden(true)
    //           setAnimate(false)
    //       }else{
    //           setHidden(false)
    //           setAnimate(true)
    //       }
    //     }, [], containerRef
    //     )
    return (
        <div className="qualification" ref= {containerRef}>
            {profileRef?.certificateUrl && (
              <div className={`${doAnimate?'jumpIn':''} ${isHidden?'hide':''}`}>
                <img
                  src={profileRef?.certificateUrl}
                  className="certicate-image"
                  alt={'certificate'}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              className={`${doAnimate?'jumpIn':''} ${isHidden?'hide':''}`}
            >
              <div className="title">Expertise</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {profileRef?.expertise?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="experties"
                      style={{
                        backgroundColor: backgroundColor,
                        color: textColor,
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
              <div className="title">Interests</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {profileRef?.interests?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="experties"
                      style={{
                        backgroundColor: backgroundColor,
                        color: textColor,
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
    )
}
export default TainerExperience