import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import "./transformation.scss";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const ImageTransformation = ({ transformationData, testimonialVideos }) => {
  const [index, setIndex] = useState(0);
  const [isHidden, setHidden] = useState(false);
  const [doAnimate, setAnimate2] = useState(true);
  const [animate, setAnimate] = useState(false);
  const transformRef = useRef();

  // useScrollPosition(
  //   ({ currPos, prevPos }) => {
  //     console.log("image transformation", currPos.y, window.outerHeight);
  //     if (currPos.y > window.outerHeight) {
  //       setHidden(true);
  //       setAnimate2(false);
  //     } else {
  //       setHidden(false);
  //       setAnimate2(true);
  //     }
  //   },
  //   [window.scrollY],
  //   transformRef
  // );

  useEffect(() => {
    console.log("transformationData");
  }, []);
  const handleClick = (i) => {
    setAnimate(true);
    const val = index + i;
    if (val < 0) {
      setIndex(transformationData.length - 1);
    } else if (val >= transformationData.length) {
      setIndex(0);
    } else {
      setIndex(val);
    }
  };
  return (
    <div className="t-container" ref={transformRef}>
      {transformationData && transformationData?.length && (
        <>
          <div
            className={`${doAnimate ? "jumpIn" : ""} ${isHidden ? "hide" : ""}`}
          >
            <h1>Transformations</h1>
          </div>
          <div className="transformation-container">
            <div
              className={`user-info ${doAnimate ? "dropTop" : ""} ${
                isHidden ? "hide" : ""
              }`}
            >
              <h2 className="btn" onClick={() => handleClick(1)}>
                &#8963;
              </h2>
              <div className={animate ? "fadeText" : ""}>
                <h2>{transformationData[index]?.name}</h2>
                <h3>{transformationData[index]?.duration}</h3>
                <h4>DAYS</h4>
              </div>
              <h2 className="btn" onClick={() => handleClick(-1)}>
                &#8964;
              </h2>
            </div>
            <div
              className={`user-data ${doAnimate ? "fadeIn" : ""} ${
                isHidden ? "hide" : ""
              }`}
            >
              <div className="transformation">
                <div>
                  <h3>{transformationData[index]?.title}</h3>
                  <img
                    src={transformationData[index]?.imageUrl}
                    className={animate ? "imgDown" : ""}
                    alt={"transformation"}
                  />
                  <h3>
                    {transformationData[index]?.beforeWeight}kg -{" "}
                    {transformationData[index]?.afterWeight}kg
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {(!testimonialVideos || testimonialVideos?.length === 0) &&
        (!transformationData || transformationData?.length === 0) && (
          <div
            className={`noData ${doAnimate ? "jumpIn" : ""} ${
              isHidden ? "hide" : ""
            }`}
          >
            {" "}
            No Transformation yet
          </div>
        )}
    </div>
  );
};
export default ImageTransformation;
