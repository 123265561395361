import React from 'react'
import PageNotFound from './Pages/PageNotFound'

const Home = React.lazy(() => import('./components/Home'))
const AboutUs = React.lazy(() => import('./components/Terms/AboutUs'))
const ContactUs = React.lazy(() =>
    import('./components/Terms/ContactUs/ContactUs'),
)
const PrivacyPolicy = React.lazy(() =>
    import('./components/Terms/PrivacyPolicy'),
)

const BlogCategories = React.lazy(() => import('./components/Blogs/BlogCategories'))
const SingleCategory = React.lazy(() => import('./components/Blogs/SingleCategory'))
const OneBlogs = React.lazy(() => import('./components/Blogs/BlogOnePage'))
const SaveBlog = React.lazy(() => import('./components/Blogs/SaveBlog'))

const routes = [
    {
        path: '/',
        exact: true,
        name: 'Home',
        component: Home,
    },
    {
        path: '/about-us',
        exact: false,
        name: 'About Us',
        component: AboutUs,
    },
    {
        path: '/contact-us',
        exact: false,
        name: 'Contact Us',
        component: ContactUs,
    },
    {
        path: '/privacy-policy',
        exact: false,
        name: 'Privacy Policy',
        component: PrivacyPolicy,
    },

    // Blogs
    {
        path: '/blogs',
        exact: true,
        name: 'All Blogs',
        component: BlogCategories,
    },
    {
        path: '/blogs/:blogSlug',
        exact: true,
        name: 'Blogs',
        component: SingleCategory,
    },
    {
        path: '/blogs/:blogSlug/:oneBlogSlug',
        exact: true,
        name: 'Blogs',
        component: OneBlogs,
    },
    {
        path: '/blogs/add',
        exact: true,
        name: 'Save Blogs',
        component: SaveBlog,
    },

    // Trainer Profile
    // {
    //     path: '/trainer-profile',
    //     exact: true,
    //     name: 'Trainer Profile',
    //     component: TrainerProfile,
    // },
    // {
    //     path: '*',
    //     exact: false,
    //     name: 'page not found',
    //     component: PageNotFound,
    // }
    // {
    //     path: '/blogs/save-blog/:blogId',
    //     exact: true,
    //     name: 'Update Blogs',
    //     component: SaveBlog,
    // },
    // {
    //     path: '/blogs/save-blog/:blogSlug/:oneBlogSlug',
    //     exact: true,
    //     name: 'Update Blogs by Category',
    //     component: SaveBlog,
    // },
]

export default routes
