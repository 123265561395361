import React, {useState } from "react";
import { Colors } from "../../Constant/color";
import { useLocation } from "react-router-dom";
// import data from "./constant.json";
import "./style.scss";
import "./transformation.scss";
import Plans from "./Plans";
import { Modal } from "react-bootstrap";
import AuthScreen from "./AuthScreen";
import auth from "../../firebase-config";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import Reviews from "./Reviews";
import Testimonials from "./Testimonials";
import AboutTrainer from "./aboutTrainer";
import ImageTransformation from "./imageTransformation";
import TrainerExperience from "./experience";
import { AppStore, GooglePlay } from "../../assets";

const TrainerProfile = ({ data }) => {
  const { pathname } = useLocation();
  const testimonialVideos = data?.trainer?.profileRef?.testimonialVideos;
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  const loginUser = () => {
    if (user) {
      signOut(auth);
    } else {
      setShowAuthModal(true);
    }
  };

  let backgroundColor = Colors.greenBg;
  let textColor = Colors.primaryText;

  backgroundColor = Colors[data?.trainer?.profileRef?.themeColor];
  textColor =
    data?.trainer?.profileRef?.themeColor === "themeDark"
      ? Colors.primaryText
      : Colors.blackText;

  

  return (
    <>
      <Modal show={showAuthModal} centered contentClassName="modal-content">
        <AuthScreen setShowAuthModal={setShowAuthModal} />
      </Modal>
      <div
        className="trainerHeader"
        style={{ backgroundColor: backgroundColor, color: textColor }}
      >
        <div className="logo" style={{ flex: 1 }}>
          <h1>{data?.trainer?.name ?? "Fitness Coach"}</h1>
        </div>

        <div className="navbar">
          {[
            "About",
            "Plans",
            data?.reviews && data?.reviews?.length > 0 ? "Reviews" : "",
          ].map((item, idx) => {
            return (
              item && (
                <a
                  key={idx}
                  className="navbar-element"
                  style={{ color: textColor }}
                  href={`#${item}`}
                >
                  {item}
                </a>
              )
            );
          })}
        </div>
        <div className="connected">
          {/* <div
            onClick={loginUser}
            className="connected-btn"
            style={{ color: textColor, borderColor: textColor }}
          >
            Get Connected
          </div> */}
          <a href="https://play.google.com/store/apps/details?id=com.evolvfitprivatelimited.user&hl=en&gl=US" target="_blank">
            <img src={GooglePlay} alt="google-play" height="48px"></img>
          </a>
          <a href="https://testflight.apple.com/join/sru5N6Fc" target="_blank">
            <img src={AppStore} alt="google-play" height="48px"></img>
          </a>
        </div>
      </div>
      <div>
        <section
          id="About"
          className="aboutSection"
          style={{ backgroundColor: Colors.primaryBg }}
        >
          {/* <div style={{fontSize: '4rem'}}>About Section</div> */}
          <AboutTrainer profileRef={data?.trainer?.profileRef} backgroundColor={backgroundColor} textColor={textColor}/>
            <ImageTransformation
              transformationData={data?.trainer?.profileRef?.transformations}
              testimonialVideos={testimonialVideos}
            />
          {testimonialVideos && testimonialVideos?.length > 0 && (
            <Testimonials testimonialVideos={testimonialVideos} />
          )}
        </section>
        <section id="Plans" style={{ backgroundColor: Colors.primaryBg }}>
          <Plans
            data={data}
            backgroundColor={backgroundColor}
            textColor={textColor}
            pathname={pathname}
            setShowAuthModal={setShowAuthModal}
            user={user}
          />
          <TrainerExperience profileRef={data?.trainer?.profileRef} backgroundColor={backgroundColor} textColor={textColor}/>
        </section>
        {data?.reviews && data?.reviews?.length > 0 && (
          <section id="Reviews" style={{ backgroundColor: Colors.primaryBg }}>
            <Reviews
              data={data}
              backgroundColor={backgroundColor}
              textColor={textColor}
              pathname={pathname}
              // setShowAuthModal={setShowAuthModal}
              user={user}
            />
          </section>
        )}
      </div>
    </>
  );
};

export default TrainerProfile;
