import React, { useState, useRef } from 'react'
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import './index.scss'
import { Modal } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import Video from './video';

const Testimonials = ({testimonialVideos}) => {  
    const carouselRef = useRef()
    const [isHidden, setHidden] = useState(false)
    const [doAnimate, setAnimate] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [id, setId] = useState()

    // useScrollPosition(
    //   ({ currPos }) => {
    //     if(currPos.y>window.outerHeight){
    //       setHidden(true)
    //       setAnimate(false)
    //     }else{
    //       setHidden(false)
    //       setAnimate(true)
    //     }
    //   }, [], carouselRef
    //   )

      const triggerModal = (id) => {
        setId(id)
        // setShowModal(true)
      }
  return (
    <div className='testimonial-container' ref={carouselRef}>
      <Modal
        show={showModal}
        className="popmodal"
        centered
        onHide={()=>setShowModal(false)}
      >
        <iframe
          title="workout"
          src={`https://www.youtube.com/embed/${id}?autoplay=1`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
        />
      </Modal>
      <div className={`${doAnimate?'jumpIn':''} ${isHidden?'hide':''}`}>
          <h1>Testimonials</h1>
      </div>
      <Swiper
        effect={"coverflow"}
        // simulateTouch={false}
        grabCursor={true}
        navigation={true}
        loop={true}
        centeredSlides={true}
        slidesPerView={3}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 0,
          slideShadows: true,
        }}
        spaceBetween={10}
        pagination={{ clickable: true }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className={`mySwiper ${doAnimate?'fadeIn':''} ${isHidden?'hide':''}`}
      >
          {testimonialVideos.map((video, idx)=>{
            return (
              <SwiperSlide key={idx}>
                {/* <Frame id={id} triggerModal={triggerModal}/> */}
                <Video video={video} triggerModal={triggerModal} idx={idx} id={id} setId={setId}/>
              </SwiperSlide>
            )
          }
          )}
      </Swiper>
    </div>
  )
}

export default Testimonials