import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { socialIcons } from './data/socialIcons'
import { companyDetails, contactUs } from './data/footerData'

import './index.scss'
import ShadowContainer from '../Common/ShadowContainer'
import ScrollAnimation from '../Common/ScrollAnimation'

import {Logo} from '../../assets'

const Footer = () => {
    const [inView, setInView] = useState(true)

    const addInView = () => {
        setInView(false)
        setTimeout(() => {
            setInView(true)
        }, 100)
    }

    const getSocialIcons = () => {
        return socialIcons.map((value, index) => (
            <a
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                href={value.link}
                className="text-green remove-a-decoration"
            >
                <img
                    className="social-icon-horizontal social-icon-horizontal-small"
                    src={value.icon}
                    alt={value.title}
                />
            </a>
        ))
    }

    const getCompanyDetails = () => {
        return companyDetails.map((value, index) => {
            return (
                <p key={index} className="m-0 text">
                    {value.field} : {value.value}
                    <br />
                </p>
            )
        })
    }

    const getContactUs = () => {
        return contactUs.map((value, index) => {
            return (
                <p key={index} className="m-0 text">
                    {value.key}:&nbsp;
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={value.link}
                        className="text-green remove-a-decoration"
                    >
                        {value.value}
                    </a>
                    <br />
                </p>
            )
        })
    }

    const getStriped = () => {
        const horizontal = ['left', 'right']
        const vertical = ['bottom', 'middle', 'top']
        let stripes = []
        horizontal.forEach((h) => {
            vertical.forEach((v) => {
                stripes.push(
                    <div
                        className={`stripes stripes-${v} stripes-${h} ${
                            inView ? `stripes-${v}-${h}` : ''
                        }`}
                    />,
                )
            })
        })
        return stripes
    }

    // TODO Clean this. Make a proper scss file
    return (
        <ShadowContainer classNames="">
            <div className="footer-container container-fluid  pt-4 pb-2 footer-z flex-shrink-0">
                <div className="d-flex justify-content-center align-items-center footer-text ">
                    <div className="logo-tagline-container">
                        <div className="logo-icon-container px-4">
                            <img
                                className="logo-icon"
                                src={Logo}
                                alt="Evolv"
                            />
                        </div>
                    </div>
                    <ScrollAnimation
                        offset={0}
                        animateOnce={false}
                        afterAnimatedIn={addInView}
                    >
                        {getStriped()}
                    </ScrollAnimation>
                </div>
                <div className="container-lg">
                    <div className='footer-grid'>
                    {/* <div className="row px-2 text-left">
                        <div className="col-12 col-sm-6 col-md-4"> */}
                        <div>
                            <h6>Company Details</h6>
                            <div className="footer-text">
                                {getCompanyDetails()}
                            </div>
                        </div>
                        {/* <div className="col-12 col-sm-6 col-md-4"> */}
                        <div>
                            <h6>Contact</h6>
                            <div className="footer-text">{getContactUs()}</div>
                        </div>
                        {/* <div className="col-12 col-sm-6 col-md-4 mx-auto"> */}
                        <div className='footer-links'>
                            <h6>Links</h6>
                            <div className="links-container">
                                <div className="col">
                                    <Link
                                        className="text-green px-1 row"
                                        to="/about-us"
                                    >
                                        ⚬ About Us
                                    </Link>
                                    <Link
                                        className="text-green px-1 row"
                                        to="/contact-us"
                                    >
                                        ⚬ Contact Us
                                    </Link>
                                    <Link
                                        className="text-green px-1 row"
                                        to="/privacy-policy"
                                    >
                                        ⚬ Privacy Policy
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 footer-row">
                    <div className="dmca-footer">
                        <a
                            href="//www.dmca.com/Protection/Status.aspx?ID=831ea5a0-94d7-481f-b019-f39ee4311eb2"
                            title="DMCA.com Protection Status"
                            className="dmca-badge"
                        >
                            <img
                                src="https://images.dmca.com/Badges/dmca_protected_11_120.png?ID=831ea5a0-94d7-481f-b019-f39ee4311eb2"
                                alt="DMCA.com Protection Status"
                            />
                        </a>
                        <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
                    </div>
                    {/* <div className="payment-container">
                        <div className="payment-method-text mr-0">
                            <p className="mb-0">Payment Methods</p>
                        </div>
                        <div className="payment-method mt-3 ml-0">
                            <img
                                src="https://storage.googleapis.com/evolv-store/icons/footer/paytm.png"
                                width="40px"
                            ></img>
                        </div>
                        <div className="payment-method mt-3">
                            <img
                                src="https://storage.googleapis.com/evolv-store/icons/footer/upi.png"
                                width="40px"
                            ></img>
                        </div>
                    </div> */}
                    <div className="right-reserved-footer mb-0">
                        <p> © 2021 EvolvFit Pvt Ltd. All Rights Reserved</p>
                    </div>
                    <div className="policies-footer d-flex">
                        <div className="footer-text d-flex justify-content-center align-items-center">
                            {getSocialIcons()}
                        </div>
                    </div>
                </div>
            </div>
        </ShadowContainer>
    )
}

export default Footer
