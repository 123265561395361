import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import './index.scss'

const Navbar = () => {
  const [isMobile, setMobile]=useState(window.innerWidth<650);
  const [toggleNav, setNav]=useState(true);
  const [isHome,setHome]=useState(true)
  useEffect(()=>{
    window.addEventListener('scroll', scrolled);
    window.addEventListener('resize', resized);

    return () => {
      window.removeEventListener('scroll', scrolled);
      window.removeEventListener('resize', resized);
      };
  })
  const resized = () => {
    setMobile(window.innerWidth<650);
  }
  const scrolled=()=>{
    if(window.scrollY>20)
      setHome(false)
    else
      setHome(true)
  }
  const menu=(
  <div className={`${isMobile?'mobile-nav':'custom-nav'}`}>
    <a href="https://train.evolvfit.in/" target="_blank" rel="noreferrer">
      <h2 className='nav-element'>Trainer</h2>
    </a>
    <a href="https://store.evolvfit.in/" target="_blank" rel="noreferrer">
      <h2 className='nav-element'>Store</h2>
    </a>
    <Link to='/blogs'><h2 className='nav-element'>Blogs</h2></Link>
  </div>)
    
    const hamburgerOff=(
      <div className={`menu-inactive ${isHome?'posabs':''}`} onClick={()=>setNav(!toggleNav)}>
        <div className='ham-strip'/>
        <div className='ham-strip'/>
        <div className='ham-strip'/>
      </div>
    )

    const hamburgerOn=(
      <div className={`menu-active ${isHome?'posabs':''}`}>
        <h1 onClick={()=>setNav(!toggleNav)}>x</h1>
        {menu}
      </div>
    )

  return (
    <div>
      {isMobile?(toggleNav?hamburgerOff:hamburgerOn):menu}
    </div>
  );
}

export default Navbar