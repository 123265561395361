import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'

const Button = ({
    classNames = '',
    text,
    icon,
    size = 'normal',
    color = 'black',
    ...props
}) => {
    return (
        <button
            className={`btn-evolv btn-${size} btn-${color} ${classNames}`}
            {...props}
        >
            {icon && <img src={icon} alt="" className="upload-button-icon" />}
            {text}
        </button>
    )
}

Button.propTypes = {
    children: PropTypes.element,
    classNames: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    icon: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
}

export default Button
