import React from 'react'
import { NavLink } from 'react-router-dom'
import './Page404and500Style.css'

const PageNotFound = ({ text = '404 Not Found' }) => {
    return (
        <div className=" container-fluid d-flex py-5 bg-gray mh-100vh ">
            <div className="mainbox-404 bg-black">
                <div className="row err-404 text-green">
                    <div className="ml-auto">4</div>
                    <td>
                        <i className=" fa-404 fas fa-question-circle fa-spin px-3"></i>
                    </td>
                    <div className="mr-auto">4</div>
                </div>
                <div className="row">
                    <div className="abel-title-small text-white mx-auto">
                        ERROR !!
                    </div>
                </div>
                <div className="text-green text-center h6">
                    Maybe this page has been moved or got deleted. Or maybe it
                    never existed in the first place?
                    <p className="h5 my-3">
                        Let's go{' '}
                        <NavLink className=" link-info text-white" to="/">
                            home{' '}
                        </NavLink>
                        and try from there.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound
