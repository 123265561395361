export const Colors= {
    primaryBg: 'rgba(32, 33, 36, 1)',
    greenBg: 'rgba(56, 204, 158, 0.42)',
    secondaryBg: '#FAFAFA',
    primaryText: 'rgba(255, 255, 255, 1)',
    lightGrey: 'rgba(94, 94, 94, 1)',
    tertiaryText: 'rgba(56, 204, 158, 1)',
    blackText: 'rgba(32, 33, 36, 1)',
    themeMagenta: '#4012A4',
    themeLightMagenta: '#CEBFEE',
    themeLightPink: '#F3C2FF',
    themeLightYellow: '#FFFDC2',
    themeDard: 'rgb(30, 37, 47)',
    themeLemonGreen: '#C3FFD1',
    themeLightGreen: '#C9FFEE',
    themeLightRed: '#FCC6C6',
    themePeach: '#FFDBD0',
    themeLightBlue: '#DDEAFD',
    themeDark: '#1E252F'

}