// const ourTeam = [
//     {
//         name: 'Prateek Jain',
//         profile: 'https://www.linkedin.com/in/prateek-jain-2572a7137/',
//         title: 'Product & Engineering',
//     },
//     {
//         name: 'Swadhin Saraf',
//         profile: 'https://www.linkedin.com/in/swadhin-saraf-171a0413b/',
//         title: 'Marketing & Growth',
//     },
//     {
//         name: 'Shagun Bandi',
//         profile: 'https://www.linkedin.com/in/shagunbandi/',
//         title: 'Technology Development',
//     },
// ]
const companyDetails = [
    {
        field: 'GSTIN',
        value: '07AAGCE2263E1ZX',
    },
    {
        field: 'Address',
        value:
            'Plot no- 40, Block D, Nangal West Vasant Kunj, South Delhi, Delhi  110070',
    },
]

const contactUs = [
    {
        key: 'Email',
        link: 'mailto:contact@evolvfit.in',
        value: 'contact@evolvfit.in',
    },
    {
        key: 'Phone',
        link: 'tel:+919334444441',
        value: '+919334444441',
    },
    {
        key: 'Contact Hrs',
        value: '10am-10pm (All days)',
    },
]

export { companyDetails, contactUs }
