// social icons - White
export const Instagram = "https://storage.googleapis.com/evolv-assets/social/white/Instagram.png"
export const Whatsapp = "https://storage.googleapis.com/evolv-assets/social/white/Whatsapp.png"
export const Facebook = "https://storage.googleapis.com/evolv-assets/social/white/Facebook.png"
export const Youtube = "https://storage.googleapis.com/evolv-assets/social/white/Youtube.png"
export const Linkedin = "https://storage.googleapis.com/evolv-assets/social/white/LinkedIn.png"
export const Mail = "https://storage.googleapis.com/evolv-assets/social/white/Mail.png"
export const GooglePlay = "https://storage.googleapis.com/evolv-assets/MVP/UserPage/PlayStore.png";
export const AppStore = "https://storage.googleapis.com/evolv-assets/MVP/UserPage/AppStore.png";

// EvolvFit Logo - White
export const Logo = "https://storage.googleapis.com/evolv-assets/brand/EvolvLogo.png"

// Images
// Mobile Image in Hero Section
export const HeroMobile = "https://storage.googleapis.com/evolv-assets/landing/AppPicture.png"

// Services Section
export const Dumbell = "https://storage.googleapis.com/evolv-assets/landing/Workout.png"
export const Nutrient = "https://storage.googleapis.com/evolv-assets/landing/Nutrition.png"

// Transformation Section
export const QuoteOpen = "https://storage.googleapis.com/evolv-assets/landing/icons/QuoteOpen.png"
export const QuoteClose = "https://storage.googleapis.com/evolv-assets/landing/icons/QuoteClose.png"

// Transformations
export const SatyamBefore = "https://storage.googleapis.com/evolv-assets/landing/transformations/before/SatyamBefore.jpeg"
export const SatyamAfter = "https://storage.googleapis.com/evolv-assets/landing/transformations/after/SatyamAfter.jpeg"
export const TanujBefore = "https://storage.googleapis.com/evolv-assets/landing/transformations/before/TanujBefore.jpeg"
export const TanujAfter = "https://storage.googleapis.com/evolv-assets/landing/transformations/after/TanujAfter.jpeg"
export const SrijanBefore = "https://storage.googleapis.com/evolv-assets/landing/transformations/before/SrijanBefore.jpeg"
export const SrijanAfter = "https://storage.googleapis.com/evolv-assets/landing/transformations/after/SrijanAfter.jpeg"

// Contact Section
export const Calender3d = 'https://storage.googleapis.com/evolv-assets/landing/icons/calendar3d.png'
export const Whatsapp3d = 'https://storage.googleapis.com/evolv-assets/landing/icons/whatsapp3d.png'

//icon
export const CloseButtonIcon = 'https://storage.googleapis.com/evolv-store/icons/common/close.svg'
