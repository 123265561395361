import React, { useRef, useState } from "react";
import { QuoteClose, QuoteOpen } from "../../../assets";
import FilledStarIcon from "../../../assets/icons/star-filled.svg";
import OutlineStarIcon from "../../../assets/icons/star-outline.svg";
import StarRatings from "react-star-ratings";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import "./index.scss";
import "../../../components/Home/animation.scss";
const Reviews = ({ data, backgroundColor, textColor, pathname, user }) => {
  const reviews = data?.reviews;
  const carouselRef = useRef();
  const [isHidden, setHidden] = useState(false);
  const [doAnimate, setAnimate] = useState(true);
  // console.log("reviews ", reviews);

  // useScrollPosition(
  //   ({ currPos }) => {
  //     if(currPos.y>window.outerHeight){
  //       setHidden(true)
  //       setAnimate(false)
  //     }else{
  //       setHidden(false)
  //       setAnimate(true)
  //     }
  //   },
  //   [],
  //   carouselRef
  // );
  return (
    <div className="reviews-section" ref={carouselRef}>
      <div className={`${doAnimate?'jumpIn':''} ${isHidden?'hide':''}`}>
          <h1>Reviews</h1>
      </div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        navigation={true}
        loop={true}
        centeredSlides={true}
        // slidesPerView={3}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 0,
          slideShadows: true,
        }}
        breakpoints={{
            0: {
                slidesPerView: 1,
            },
            1024:{
                slidesPerView: 3,
            }
        }}
        slidesPerView={3}
        spaceBetween={10}
        pagination={{ clickable: true }}
        modules={[EffectCoverflow,  Navigation]}
        className={`mySwiper ${doAnimate ? "fadeIn" : ""} ${
          isHidden ? "hide" : ""
        }`}
      >
        {reviews.map((review, index) => {
          return (
            review?.description &&
            <SwiperSlide key={index}>
              <div key={index} className="review-container">
                
                <div className="testimonial">
                  <img src={QuoteOpen} className="left-quote" />
                  <p>{review?.description}</p>
                  <img src={QuoteClose} className="right-quote" />
                </div>
                <div className="review-rating">
                  <StarRatings
                    rating={review?.rating}
                    starRatedColor="yellow"
                    starDimension="20px"
                    starSpacing="3px"
                    starEmptyColor="#D9D9D9"
                    // changeRating={this.changeRating}
                    numberOfStars={5}
                    name="rating"
                  />
                  <div>~ {review?.userRef?.name}</div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Reviews;
