import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';

const MainWebLayout = ({children}) => {
    return ( 
        <>
            <Navbar/>
            {children}
            <Footer/>
        </>
     );
}
 
export default MainWebLayout;